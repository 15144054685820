import React from "react";



export default function NotFound() {
  return (
    <div className="content">
      <h3 style={{paddingTop:"100px", textAlign: "center"}}>Sorry, page not found!</h3>
    </div>
  );
}